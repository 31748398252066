import React from "react";
import TitleDiv from "../../../../components/controls/TitleDiv";
import Header from "../../../../components/sections/Header";
import HeadData from "../../../../data/HeadData";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import ContentContainer from "../../../../components/container/ContentContainer";
import ContactExpert from "../../../../components/controls/ContactExpert";
import ButtonAction from "../../../../components/controls/ButtonAction";

// FILES
import stevensWoodSpecification from "../../../../downloads/2018/04/StevensWood-TFL-Specifcation.pdf";
import stevensWoodCareMaintenance from "../../../../downloads/2018/04/StevensWood_Care_Maintenance_11-16.pdf";
import SimpleReactLightbox from "simple-react-lightbox";
import Thumbnail from "../../../../components/controls/Thumbnail";
import Layout from "../../../../components/layout/Layout";

const ArtikaPage = () => {
  const path = ["Textured Melamine in Southern California", "The Artika & Rain Collection"];

  const gallery = useStaticQuery(graphql`
    {
      artika: file(relativePath: { eq: "textured-melamine/8.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      thumb: allFile(
        filter: { relativeDirectory: { eq: "textured-melamine/gallery" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      hiRes: allFile(
        filter: {
          relativeDirectory: { eq: "textured-melamine/gallery/hi-res" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="The Artika & Rain Collection" 
          description="StevensWood’s® Artika® & Rain® collection provides architects and designers with opportunities to bring interior design to the next level."
        />
        <Header />
        <TitleDiv
          parentText="Products"
          title="The Artika & Rain Collection"
          className="mb-8"
          path={path}
        />
        <ContentContainer className="bg-gray5 text-center py-12">
          <h2>Collection</h2>
        </ContentContainer>
        <ContentContainer className="bg-gray2 py-12 mb-9 px-5 xl:px-32">
          <div className="flex flex-col text-center items-center">
            <p className="text-3xl">The Artika & Rain Collection</p>
            <hr className="border-blue1 border-t-2 mt-4 w-56" />
            <div className="flex flex-col lg:flex-row flex-wrap w-full pt-14 gap-x-0 gap-y-0 sm:gap-y-11">
              <BackgroundImage
                className="w-full min-h-72 sm:min-h-96 lg:w-1/2"
                {...convertToBgImage(getImage(gallery.artika))}
              >
                &nbsp;
              </BackgroundImage>
              <div className="w-full lg:w-1/2 md:bg-gray1 py-10 md:py-20 px-0 md:px-12 text-left">
                <p>
                  StevensWood’s® Artika® & Rain® collection provides architects
                  and designers with opportunities to bring interior design to the
                  next level. Enhanced textures along with realistic and trendy
                  patterns offer the perfect solution for the needs and lifestyles
                  of today. Artika features a wider textured look, while Rain
                  comes with a tighter textured look.
                  <br />
                  <br />
                  Available in 4' x 8' panels and some 5' x 8' panels, call for
                  size and availability.
                </p>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-x-10 gap-y-5">
                <div className="w-full sm:w-1/2">
                  <StaticImage
                    alt="Artika"
                    layout="fullWidth"
                    placeholder="blurred"
                    quality={100}
                    src="../../../../images/textured-melamine/artika.png"
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <StaticImage
                    alt="Rain"
                    layout="fullWidth"
                    placeholder="blurred"
                    quality={100}
                    src="../../../../images/textured-melamine/rain.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="md:bg-gray5 pt-7 md:py-7 px-5 sm:px-32 font-bold md:font-normal text-lg md:text-base text-center md:text-left">
          <p>Artika and Rain are available in a variety of colors:</p>
        </ContentContainer>
        <ContentContainer className="md:bg-gray2 py-12 mb-9 px-5 sm:px-32">
          <SimpleReactLightbox>
            <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-4 sm:gap-x-8 gap-y-12 text-center items-start">
              {gallery.thumb.edges.map((thumbnail, index) => {
                return (
                  <Thumbnail
                    key={`gallery${index}`}
                    hiRes={
                      gallery.hiRes.edges[index].node.childImageSharp
                        .gatsbyImageData.images.fallback.src
                    }
                    name={thumbnail.node.name}
                    thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                  />
                );
              })}
            </div>
          </SimpleReactLightbox>
        </ContentContainer>

        <ContentContainer className="bg-gray5 text-center py-12 mx-4 md:mx-0">
          <h2>Specifier</h2>
        </ContentContainer>
        <ContentContainer className="md:bg-gray2 pb-12 md:py-12 mb-9 px-4 md:px-0">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-5 md:gap-y-20 sm:px-20">
            <div className="flex flex-col gap-y-10 p-5 md:p-0 bg-gray2 md:bg-transparent">
              <p className="text-3xl w-full">StevensWood Textured Melamine</p>
              <p className="lg:pr-20 lg:min-h-52">
                The following document is provided to assist design professionals
                with product specifications, general information and language
                standards for paneling, casework, countertops, cabinetry, interior
                closets, residential and office furniture, shop and job site
                application of millwork finishes and similar architectural
                woodwork.
              </p>
              <div className="flex flex-col items-center lg:pr-20">
                <ButtonAction
                  href={stevensWoodSpecification}
                  newWindow={true}
                  className="bg-actionBlue text-white px-20"
                >
                  View PDF
                </ButtonAction>
              </div>
            </div>
            <div className="flex flex-col gap-y-10 p-5 md:p-0 bg-gray2 md:bg-transparent">
              <p className="text-3xl w-full">StevensWood Care & Maintenance</p>
              <p className="lg:pr-20 sm:min-h-52">
                Proper care and maintenance of StevensWood surfacing will bring
                years of quality performance. StevensWood laminates resist muddy
                fingerprints, coffee spills and so much more. Be sure to read the
                following document for the recommended care and cleaning
                procedures.
              </p>
              <div className="flex flex-col items-center lg:pr-20">
                <ButtonAction
                  href={stevensWoodCareMaintenance}
                  newWindow={true}
                  className="bg-actionBlue text-white px-20"
                >
                  View PDF
                </ButtonAction>
              </div>
            </div>
          </div>
        </ContentContainer>
        {/* <ContentContainer className="bg-gray2 py-12 mb-9">
          <div className="flex flex-col space-y-10">
            <div className="grid grid-cols-2 px-20">
              <p className="text-3xl w-full">StevensWood Textured Melamine</p>
              <p className="text-3xl w-full">StevensWood Care & Maintenance</p>
            </div>
            <div className="grid grid-cols-2 px-20">
              <p className="pr-20">
                The following document is provided to assist design professionals
                with product specifications, general information and language
                standards for paneling, casework, countertops, cabinetry, interior
                closets, residential and office furniture, shop and job site
                application of millwork finishes and similar architectural
                woodwork.
              </p>
              <p className="pr-20">
                Proper care and maintenance of StevensWood surfacing will bring
                years of quality performance. StevensWood laminates resist muddy
                fingerprints, coffee spills and so much more. Be sure to read the
                following document for the recommended care and cleaning
                procedures.
              </p>
            </div>

            <div className="grid grid-cols-2 px-20">
              <div className="flex flex-col items-center pr-20">
                <ButtonAction
                  href={stevensWoodSpecification}
                  newWindow={true}
                  className="bg-actionBlue text-white px-20"
                >
                  View PDF
                </ButtonAction>
              </div>
              <div className="flex flex-col items-center pr-20">
                <ButtonAction
                  href={stevensWoodCareMaintenance}
                  newWindow={true}
                  className="bg-actionBlue text-white px-20"
                >
                  View PDF
                </ButtonAction>
              </div>
            </div>
          </div>
        </ContentContainer> */}

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default ArtikaPage;
